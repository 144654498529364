export const defaultState = () => ({
  // Firebase
  firebaseUser: null,

  // Static data
  club: null,
  clubSubscriptions: [],

  // Firebase user data
  user: null,
  userSubscription: null,
  userClubSpecific: null,
  selectedSubscriptionId: null,

  // UI
  platform: 'unknown',
  preSelectedDate: null,
  preSelectedSubscriptionId: null,
  appImages: {},

  // User filled data
  newUser: {},
  newUserClubSpecific: {},
  newSelectedSubscriptionId: null,

  // To refactor
  billingInfo: {},
});

export default defaultState();
