<template>
  <transition name="fade">
    <div v-if="isLoading" class="app-loading">
      <img src="~@/assets/icons/logo.svg" class="loading-logo" />
    </div>
  </transition>

  <div class="not-found" v-if="clubNotFound">
    <img src="~@/assets/icons/logo.svg" class="logo" />
    <div class="text">Sorry, the club that referred you doesn't appear to have an active KLUBBA subscription. Please check your link or contact your referring club.</div>
  </div>

  <transition name="margin-fade-header">
    <KlubbaHeader v-if="!$route?.meta?.noHeader" :show-club="$route?.meta?.clubPage" />
  </transition>

  <transition name="margin-fade-header">
    <KlubbaProgress v-if="$route?.meta?.progress" :value="$route?.meta?.progress" />
  </transition>

  <router-view v-slot="{ Component }">
    <transition name="page-fade" mode="out-in">
      <component v-if="!isLoading && club" :is="Component" />
    </transition>
  </router-view>

  <div id="recaptcha" />
</template>

<script>
import firebase from 'firebase';
import { mapGetters } from 'vuex';
import firebaseApp from '@/config/firebase';
import vhFix from '@/helpers/vhFix';
import KlubbaHeader from '@/components/layout/KlubbaHeader.vue';
import KlubbaProgress from './components/default/KlubbaProgress.vue';
// eslint-disable-next-line import/no-named-as-default
import useRedirectRules from './router/rules';
import { getImageFromStorage } from './tools/getImageFromStorage';

const CryptoJS = require('crypto-js');

export default {
  components: { KlubbaProgress, KlubbaHeader },
  data() {
    return {
      isLoading: true,
      clubNotFound: false,
      authLogicInitialized: false,
    };
  },
  computed: {
    ...mapGetters(['club', 'clubSpecific', 'clubSubscriptions']),
    clubId() {
      return this.$route?.params?.id ?? '';
    },
  },
  beforeCreate() {
    vhFix();
  },
  mounted() {
    this.$router.isReady().then(() => {
      if (this.clubId !== this.club?.id) {
        this.$store.commit('resetState');
      }

      this.setPlatform();
      this.recaptchaInit();
      this.getClub();
    });
  },
  methods: {
    recaptchaInit() {
      window.recaptchaVerifier = new firebase.auth.RecaptchaVerifier('recaptcha', {
        size: 'invisible',
        callback: (response) => {
          console.log('Recaptcha response: ', response);
        },
        error: (error) => {
          console.log('Recaptcha error: ', error);
          window.recaptchaVerifier.clear();
          setTimeout(() => {
            window.recaptchaVerifier.render();
          }, 1000);
        },
      });
    },
    setColors() {
      document.documentElement.style.setProperty('--disabled-fore-color', this.club.disabled_fore_color);
      document.documentElement.style.setProperty('--primary-fore-color', this.club.primary_fore_color);
      document.documentElement.style.setProperty('--primary-back-color', this.club.primary_back_color);
      document.documentElement.style.setProperty('--secondary-fore-color', this.club.secondary_fore_color);
      document.documentElement.style.setProperty('--secondary-back-color', this.club.secondary_back_color);
    },
    async getClub() {
      const db = firebaseApp.firestore();
      const clubExists = this.clubId?.length > 0 ? (await db.collection('clubs').doc(this.clubId).get()).exists : false;

      if (this.$route.fullPath === '/' || this.clubId?.length === 0 || !this.clubId || !clubExists) {
        this.clubNotFound = true;
        this.isLoading = false;
        return;
      }

      this.isLoading = true;

      // Loading Auth logics and club
      await Promise.all([
        this.authLogic(),

        this.$store.dispatch('bindFirebaseRef', {
          ref: db.collection('clubs').doc(this.clubId),
          mutationName: 'setClub',
        }),

        this.$store.dispatch('bindFirebaseRef', {
          ref: db.collection('clubs').doc(this.clubId).collection('subscriptions'),
          mutationName: 'setSubscriptions',
        }),
      ]);

      // Additional functions
      this.setColors();
      this.savePreSelectedDate();
      this.savePreSelectedSubscriptionId();
      this.clearPreSelected();
      await this.preloadImages();

      console.log('⭯ Firebase user: ', this.$store.getters.firebaseUser);
      console.log('⭯ Club: ', this.$store.getters.club);
      console.log('⭯ Club subscriptions: ', this.$store.getters.clubSubscriptions);
      console.log('⭯ User data: ', this.$store.getters.user);
      console.log('⭯ User club specific: ', this.$store.getters.clubSpecific);
      console.log('⭯ User subscription: ', this.$store.getters.userSubscription);
      console.log('⭯ Selected club subscription: ', this.$store.getters.selectedClubSubscription);
      console.log('⭯ Selected subscription id: ', this.$store.getters.selectedSubscriptionId);
      console.log('⭯ Platform: ', this.$store.getters.platform);
      console.log('⭯ Pre-selected start date: ', this.$store.getters.preSelectedDate);
      console.log('⭯ Pre-selected subscription id: ', this.$store.getters.preSelectedSubscriptionId);
      console.log('#### INITIAL LOADING FINISHED! ####');

      this.isLoading = false;
    },
    setPlatform() {
      const ua = navigator.userAgent || navigator.vendor || window.opera;

      if (/android/i.test(ua)) {
        this.$store.commit('savePlatform', 'android');
      } else if (/iPad|iPhone|iPod/.test(ua) && !window.MSStream) {
        this.$store.commit('savePlatform', 'ios');
      }
    },
    savePreSelectedDate() {
      /*
      If user gets a link with pre-selected date, we save it in vuex
      and user won't be able to edit it
       */
      const query = { ...this.$route.query };
      const preSelectedDate = query?.d ?? null;

      if (preSelectedDate) {
        const decryptedDate = CryptoJS.enc.Base64.parse(preSelectedDate).toString(CryptoJS.enc.Utf8);
        this.$store.commit('setPreSelectedDate', decryptedDate);
        delete query.d;
        this.$router.replace({ path: this.$route.path, query: { ...query } });
      }
    },
    clearPreSelected() {
      /*
      Dev query parameter to clear pre selected values
       */
      const query = { ...this.$route.query };

      if (query.clearPreSelected) {
        this.$store.commit('setPreSelectedDate', null);
        this.$store.commit('setPreSelectedSubscriptionId', null);
        delete query.clearPreSelected;
        this.$router.replace({ path: this.$route.path, query: { ...query } });
      }
    },
    savePreSelectedSubscriptionId() {
      /*
      If user gets a link with pre-selected subscription, we save it in vuex
      and user won't be able to edit it
       */
      const query = { ...this.$route.query };
      const preSelectedSubscriptionId = query?.s ?? null;

      if (preSelectedSubscriptionId) {
        this.$store.commit('setPreSelectedSubscriptionId', preSelectedSubscriptionId);
        delete query.s;
        this.$router.replace({ path: this.$route.path, query: { ...query } });
      }
    },
    async preloadImages() {
      /*
      Function for preloading all images
       */

      const imagesRawPaths = [];
      const images = {};

      // Parse raw images paths
      if (this.club?.logo_image) imagesRawPaths.push(this.club.logo_image);
      if (this.club?.splash_image) imagesRawPaths.push(this.club.splash_image);
      if (this.club?.wallet_image) imagesRawPaths.push(this.club.wallet_image);
      if (this.club?.subscription_image) imagesRawPaths.push(this.club.subscription_image);
      if (this.clubSubscriptions?.length > 0) {
        this.clubSubscriptions.forEach((subscription) => {
          imagesRawPaths.push(subscription.image);
        });
      }

      // Load real URLs
      // eslint-disable-next-line no-async-promise-executor
      await Promise.all(imagesRawPaths.map((path) => new Promise(async (resolve) => {
        const imageURL = await getImageFromStorage(path);
        if (imageURL.length > 0) {
          images[path] = imageURL;
          const image = new Image();
          image.src = imageURL;
          image.onload = () => {
            resolve();
            console.log(`Image loaded! ${imageURL}`);
          };
        }
      })));

      // Save images to storage
      this.$store.commit('saveAppImages', images);
    },
    async authLogic() {
      if (this.authLogicInitialized) return; // Don't repeat the code below
      this.authLogicInitialized = true;

      const db = firebaseApp.firestore();

      await firebaseApp.auth().onAuthStateChanged(async (user) => {
        this.$store.commit('setFirebaseUser', user);

        // Check if user profile exists
        if (user !== null) {
          await Promise.all([
            // Load user data
            this.$store.dispatch('bindFirebaseRef', {
              ref: db.collection('users').doc(user.uid),
              mutationName: 'setUser',
            }),

            // Load user subscription for current club
            this.$store.dispatch('bindFirebaseRef', {
              ref: db.collection('users').doc(user.uid).collection('subscriptions').doc(this.clubId),
              mutationName: 'setUserSubscription',
            }),

            // Load user club specific info
            this.$store.dispatch('bindFirebaseRef', {
              ref: db.collection('users').doc(user.uid).collection('club_specific').doc(this.clubId),
              mutationName: 'setUserClubSpecific',
            }),
          ]);
        } else {
          this.$store.commit('unbindFirestoreSubscription', 'setUser');
          this.$store.commit('unbindFirestoreSubscription', 'setUserSubscription');
          this.$store.commit('unbindFirestoreSubscription', 'setUserClubSpecific');
        }

        // If logged out, redirect to different page
        if (user === null && this.$route?.meta?.redirectOnLogOut) {
          await this.$router.push(`/${this.$route.params.id}/${this.$route.meta.redirectOnLogOut}`);
        }

        // Save join date and state
        if (this.$store.getters.firebaseUser) {
          if (!this.clubSpecific?.join_date) this.$store.commit('setNewUserClubSpecific', { join_date: firebase.firestore.Timestamp.now().seconds * 1000 });
          if (!this.clubSpecific?.state) this.$store.commit('setNewUserClubSpecific', { state: 'inactive' });
        }
      });

      // Init redirect rules
      useRedirectRules(this.$route, this);
      this.$router.beforeEach((guard) => {
        useRedirectRules(guard, this);
      });
    },
  },
};
</script>

<style lang="sass" scoped>
.app-loading
  height: calc(var(--vh, 1vh) * 100)
  z-index: 300
  @apply flex items-center justify-center fixed top-0 left-0 m-0 w-full bg-primary-dark

  .loading-logo
    @apply w-20 block
    animation: custom-bounce 2s ease infinite

.not-found
  height: calc(var(--vh, 1vh) * 100)
  z-index: 299
  @apply flex justify-center items-center flex-col text-center fixed top-0 left-0 m-0 w-full bg-primary-dark p-7 box-border

  .logo
    @apply w-12 block

  .text
    font-size: 400
    color: white
    font-size: 16px
    line-height: 1.5
    @apply mt-10

    a
      font-size: inherit
      line-height: inherit
      transition: color var(--transition)
      text-decoration: underline
      font-weight: 700
      @apply text-primary

      &:hover
        @apply text-primary-hover
</style>
