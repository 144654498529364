<template>
  <div class="section-progress">
    <div class="wrapper">
      <div class="fill" :style="{ width: `${value}%` }" />
    </div>
  </div>
</template>

<script>
export default {
  props: {
    value: {
      type: Number,
      default: 0,
    },
  },
};
</script>

<style lang="sass" scoped>
.section-progress
  @apply px-8 pt-8 w-full

.wrapper
  @apply rounded-radius-10 h-3 bg-background-light

.fill
  @apply rounded-radius-10 h-full
  transition: width var(--transition-2x)
  background: var(--primary-back-color, var(--klubba-primary-back-color))
</style>
