export default function useRedirectRules(route, app) {
  const rules = route?.meta?.rules ?? [];

  if (rules.length > 0) {
    const allRulesAndValues = {
      // Auth
      notAuthOnly: {
        value: !app.$store.getters.firebaseUser,
        redirectURL: `/${app.$route.params.id}/account-review`,
      },
      authOnly: {
        value: !!app.$store.getters.firebaseUser,
        redirectURL: `/${app.$route.params.id}/request-code?proceedTo=${app.$route.fullPath.split('/').at(-1)}`,
      },

      // Subscription
      onlyWithoutSubscription: {
        value: !app.$store.getters.userSubscription,
        redirectURL: `/${app.$route.params.id}/account-review`,
      },
      onlyWithSubscription: {
        value: !!app.$store.getters.userSubscription,
        redirectURL: `/${app.$route.params.id}/product-details`,
      },

      // Club specific
      onlyWithClubSpecific: {
        value: !!app.$store.getters.clubSpecific,
        redirectURL: `/${app.$route.params.id}/email`,
      },
    };

    // eslint-disable-next-line no-restricted-syntax
    for (const rule of rules) {
      const ruleState = allRulesAndValues[rule];
      if (!ruleState.value) {
        app.$router.push(ruleState.redirectURL);
        break;
      }
    }
  }
}
