<template>
<div class="klubba-rich-text">
  <slot />
</div>
</template>

<script>
export default {
  name: 'KlubbaRichText',
};
</script>

<style lang="sass" scoped>
.klubba-rich-text
  @apply tracking-spacing-7
  line-height: 1.5

  a, :deep(a)
    @apply font-medium
    color: var(--primary-back-color, var(--klubba-primary-back-color))
    text-decoration: underline

  b, :deep(b)
    @apply font-bold

  > *:not(:first-child), :deep(> *:not(:first-child))
    margin-top: 1.5em

  li, :deep(li)
    @apply pl-6 relative
    &::before
      @apply absolute w-2 h-2 rounded-full bg-primary left-0 top-2
      content: ''

  h1, :deep(h1)
    @apply font-bold
    font-size: 22px

  h2, :deep(h2)
    @apply font-bold
    font-size: 17px

  hr, :deep(hr)
    @apply border-none opacity-10
    border-top: 1px solid currentColor
</style>
