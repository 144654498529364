import { createApp } from 'vue';
import VueLazyLoad from 'vue3-lazyload';
import App from './App.vue';
import './registerServiceWorker';
import router from './router';
import store from './store';
import './assets/css/tailwind.scss';
import './assets/css/transitions.scss';
import './assets/css/global.scss';
import {
  backgroundImageFromStorageDirective,
  imageFromStorageDirective,
} from './tools/getImageFromStorage';

createApp(App)
  .use(store)
  .use(router)
  .use(VueLazyLoad)
  .directive('bg-image-from-storage', backgroundImageFromStorageDirective)
  .directive('image-from-storage', imageFromStorageDirective)
  .mount('#app');
