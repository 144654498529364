import { createRouter, createWebHistory } from 'vue-router';

const routes = [
  {
    path: '/',
    component: () => import('@/views/Welcome/Home.vue'),
    meta: {
      noHeader: true,
    },
  },
  {
    path: '/:id/',
    component: () => import('@/views/Welcome/Home.vue'),
    meta: {
      noHeader: true,
    },
  },
  {
    path: '/:id/product-details',
    component: () => import('@/views/Welcome/ProductDetails.vue'),
    meta: {
      clubPage: true,
      rules: ['onlyWithoutSubscription'],
    },
  },
  /*
  Log In / Register
   */
  {
    path: '/:id/request-code',
    component: () => import('@/views/Verify/RequestCode.vue'),
    meta: {
      rules: ['notAuthOnly'],
    },
  },
  {
    path: '/:id/enter-code',
    component: () => import('@/views/Verify/EnterCode.vue'),
    meta: {
      rules: ['notAuthOnly'],
    },
  },
  /*
  Add subscription
   */
  {
    path: '/:id/add-wallet',
    component: () => import('@/views/ExistingUser/AddWallet.vue'),
    meta: {
      clubPage: true,
      progress: 15,
      redirectOnLogOut: 'request-code?proceedTo=add-wallet',
      rules: ['authOnly', 'onlyWithoutSubscription', 'onlyWithClubSpecific'],
    },
  },
  {
    path: '/:id/start-date',
    component: () => import('@/views/ExistingUser/StartDate.vue'),
    meta: {
      clubPage: true,
      progress: 30,
      redirectOnLogOut: 'request-code?proceedTo=start-date',
      rules: ['authOnly', 'onlyWithoutSubscription', 'onlyWithClubSpecific'],
    },
  },
  {
    path: '/:id/club-questions',
    component: () => import('@/views/ExistingUser/ClubQuestions.vue'),
    meta: {
      clubPage: true,
      progress: 45,
      redirectOnLogOut: 'request-code?proceedTo=club-questions',
      rules: ['authOnly', 'onlyWithoutSubscription', 'onlyWithClubSpecific'],
    },
  },
  {
    path: '/:id/additional',
    component: () => import('@/views/ExistingUser/Additional.vue'),
    meta: {
      clubPage: true,
      progress: 60,
      redirectOnLogOut: 'request-code?proceedTo=additional',
      rules: ['authOnly', 'onlyWithoutSubscription', 'onlyWithClubSpecific'],
    },
  },
  {
    path: '/:id/confirmation',
    component: () => import('@/views/ExistingUser/Confirmation.vue'),
    meta: {
      clubPage: true,
      progress: 75,
      redirectOnLogOut: 'request-code?proceedTo=confirmation',
      rules: ['authOnly', 'onlyWithoutSubscription', 'onlyWithClubSpecific'],
    },
  },
  {
    path: '/:id/payment',
    component: () => import('@/views/ExistingUser/Payment.vue'),
    meta: {
      clubPage: true,
      progress: 90,
      redirectOnLogOut: 'request-code?proceedTo=payment',
      rules: ['authOnly', 'onlyWithoutSubscription', 'onlyWithClubSpecific'],
    },
  },
  {
    path: '/:id/account-review',
    component: () => import('@/views/ExistingUser/AccountReview.vue'),
    meta: {
      clubPage: true,
      progress: 100,
      redirectOnLogOut: 'request-code?proceedTo=account-review',
      rules: ['authOnly', 'onlyWithClubSpecific'],
    },
  },
  {
    path: '/:id/subscription-added',
    component: () => import('@/views/ExistingUser/SubscriptionAdded.vue'),
    meta: {
      clubPage: true,
      redirectOnLogOut: 'request-code?proceedTo=subscription-added',
      rules: ['authOnly', 'onlyWithSubscription', 'onlyWithClubSpecific'],
    },
  },
  /*
  New User or Old User Updates
   */
  {
    path: '/:id/email',
    component: () => import('@/views/NewUser/Email.vue'),
    meta: {
      progress: 20,
      redirectOnLogOut: 'request-code?proceedTo=email',
      rules: ['authOnly'],
    },
  },
  {
    path: '/:id/name',
    component: () => import('@/views/NewUser/Name.vue'),
    meta: {
      progress: 40,
      redirectOnLogOut: 'request-code?proceedTo=name',
      rules: ['authOnly'],
    },
  },
  {
    path: '/:id/other',
    component: () => import('@/views/NewUser/Other.vue'),
    meta: {
      progress: 60,
      rules: ['authOnly'],
      redirectOnLogOut: 'request-code?proceedTo=other',
    },
  },
  {
    path: '/:id/notifications',
    component: () => import('@/views/NewUser/Notifications.vue'),
    meta: {
      progress: 80,
      rules: ['authOnly'],
      redirectOnLogOut: 'request-code?proceedTo=notifications',
    },
  },
  {
    path: '/:id/card-review',
    component: () => import('@/views/NewUser/CardReview.vue'),
    meta: {
      progress: 100,
      rules: ['authOnly'],
      redirectOnLogOut: 'request-code?proceedTo=card-review',
    },
  },
  /*
  Cancel subscription
   */
  {
    path: '/:id/cancel-request-code',
    component: () => import('@/views/Cancel/CancelRequestCode.vue'),
    meta: {
      clubPage: true,
    },
  },
  {
    path: '/:id/cancel-enter-code',
    component: () => import('@/views/Cancel/CancelEnterCode.vue'),
    meta: {
      clubPage: true,
      redirectOnLogOut: 'request-code?proceedTo=cancel-enter-code',
      rules: ['authOnly'],
    },
  },
  {
    path: '/:id/subscription-removed',
    component: () => import('@/views/ExistingUser/SubscriptionRemoved.vue'),
    meta: {
      clubPage: true,
      redirectOnLogOut: 'request-code?proceedTo=subscription-removed',
      rules: ['authOnly'],
    },
  },
  {
    path: '/:pathMatch(.*)*',
    component: () => import('@/views/Welcome/Home.vue'),
    meta: {
      noHeader: true,
    },
  },
  {
    path: '/:pathMatch(.*)',
    component: () => import('@/views/Welcome/Home.vue'),
    meta: {
      noHeader: true,
    },
  },
];

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes,
});

router.beforeEach(async () => {
  window.scrollTo({ top: 0, behavior: 'smooth' });
});

export default router;
