// Mutation to save subscription for Firestore ref updates
function saveFirestoreSubscription(state, { mutationName, subscription }) {
  if (!state.firestoreSubscriptions?.[mutationName]) {
    state.firestoreSubscriptions[mutationName] = subscription;
    console.warn(`🠖 Vuex/Firestore bind | Mutation ${mutationName}`);
  }
}

// Mutation to remove subscription from Firestore ref updates
function unbindFirestoreSubscription(state, mutationName) {
  if (state.firestoreSubscriptions?.[mutationName]) {
    state.firestoreSubscriptions[mutationName]();
    delete state.firestoreSubscriptions[mutationName];
    console.warn(`🠖 Vuex/Firestore unbind | Mutation ${mutationName}`);
  }
}

// Function to subscribe to Firestore ref updates
async function subscribeToUpdates(ref, mutationName, context, targetParameter) {
  return new Promise((resolve, reject) => {
    if (!context.state.firestoreSubscriptions?.[mutationName]) {
      context.commit('saveFirestoreSubscription', {
        mutationName,
        subscription: ref.onSnapshot(
          async (snapshot) => {
            const isArray = snapshot?.docs;
            let data = isArray ? [] : null;

            if (isArray && snapshot.docs.length > 0) {
              data = snapshot.docs.map((doc) => {
                const docData = doc.data();
                return {
                  id: doc.id,
                  ...docData,
                };
              });
            } else if (!isArray && snapshot.exists) {
              data = {
                id: snapshot.id,
                ...snapshot.data(),
              };
            }

            // eslint-disable-next-line no-unused-expressions
            targetParameter
              ? await context.commit(mutationName, {
                data,
                parameter: targetParameter,
              })
              : await context.commit(mutationName, data);
            resolve();
          },
          (error) => {
            reject(new Error(`Error bind for mutation ${mutationName}: ${error.message}. Please reload the page`));
          },
        ),
      });
    } else {
      resolve(new Error(`Firestore was already bind to this ref by mutation ${mutationName}`));
    }
  });
}

export default saveFirestoreSubscription;
export { saveFirestoreSubscription, subscribeToUpdates, unbindFirestoreSubscription };
