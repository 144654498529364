<template>
  <transition name="fade">
    <div class="base-modal" v-if="active">
      <div class="background" @click="handleClose" />
      <div class="content">
        <inline-svg
          class="close"
          :src="require('@/assets/icons/close.svg')"
          @click="handleClose"
        />
        <slot />
      </div>
    </div>
  </transition>
</template>

<script>
import InlineSvg from 'vue-inline-svg';

export default {
  name: 'BaseModal',
  props: {
    active: {
      type: Boolean,
    },
  },
  components: {
    InlineSvg,
  },
  methods: {
    handleClose() {
      this.$emit('close');
    },
  },
};
</script>

<style lang="sass" scoped>
.base-modal
  @apply fixed top-0 left-0 w-full px-4 pb-4 pt-20 overflow-auto text-primary-dark
  height: calc(var(--vh, 1vh) * 100)
  z-index: 9999

  .background
    @apply opacity-70 fixed top-0 left-0 w-full h-full bg-primary-dark

  .close
    @apply absolute cursor-pointer right-2
    top: -52px

  .content
    @apply p-8 relative bg-white rounded-radius-10 mr-auto ml-auto
    z-index: 1
    max-width: 418px

</style>
