import firebaseInstance from '../config/firebase';

export async function getImageFromStorage(filePath) {
  let imageUrl = '';

  if (filePath?.length > 0) {
    await firebaseInstance
      .storage()
      .ref()
      .child(filePath)
      .getDownloadURL()
      .then((url) => {
        imageUrl = url;
      })
      .catch((error) => {
        console.error(`Could not load the image ${filePath}`, error.code);
      });
  }

  return imageUrl;
}

export const backgroundImageFromStorageDirective = {
  async mounted(el, binding) {
    if (binding.value !== binding.oldValue) {
      const preloadedUrl = binding.instance.$store.getters.appImages?.[binding.value];

      // eslint-disable-next-line no-param-reassign
      el.setAttribute('lazy', 'loading');
      // eslint-disable-next-line no-param-reassign
      el.style.backgroundImage = `url(${preloadedUrl ?? await getImageFromStorage(binding.value)})`;
      el.setAttribute('lazy', 'loaded');
    }
  },
  async updated(el, binding) {
    if (binding.value !== binding.oldValue) {
      const preloadedUrl = binding.instance.$store.getters.appImages?.[binding.value];

      // eslint-disable-next-line no-param-reassign
      el.setAttribute('lazy', 'loading');
      // eslint-disable-next-line no-param-reassign
      el.style.backgroundImage = `url(${preloadedUrl ?? await getImageFromStorage(binding.value)})`;
      el.setAttribute('lazy', 'loaded');
    }
  },
};

export const imageFromStorageDirective = {
  async mounted(el, binding) {
    if (binding.value !== binding.oldValue) {
      const preloadedUrl = binding.instance.$store.getters.appImages?.[binding.value];

      // eslint-disable-next-line no-param-reassign
      el.setAttribute('lazy', 'loading');
      // eslint-disable-next-line no-param-reassign
      el.src = preloadedUrl ?? await getImageFromStorage(binding.value);
      el.setAttribute('lazy', 'loaded');
    }
  },
  async updated(el, binding) {
    if (binding.value !== binding.oldValue) {
      const preloadedUrl = binding.instance.$store.getters.appImages?.[binding.value];

      // eslint-disable-next-line no-param-reassign
      el.setAttribute('lazy', 'loading');
      // eslint-disable-next-line no-param-reassign
      el.src = preloadedUrl ?? await getImageFromStorage(binding.value);
      el.setAttribute('lazy', 'loaded');
    }
  },
};
