import { createStore } from 'vuex';
import createPersistedState from 'vuex-persistedstate';
import { defaultState } from './defaultState';
import { subscribeToUpdates, saveFirestoreSubscription, unbindFirestoreSubscription } from '../tools/subscribeToUpdates';

export default createStore({
  state: {
    ...defaultState(),
    firestoreSubscriptions: [],
  },
  getters: {
    firebaseUser(state) {
      return state.firebaseUser;
    },
    club(state) {
      return state.club;
    },
    clubSubscriptions(state) {
      return state.clubSubscriptions;
    },
    selectedSubscriptionId(state) {
      return state.newSelectedSubscriptionId ?? state.selectedSubscriptionId;
    },
    selectedClubSubscription(state, getters) {
      if (getters.selectedSubscriptionId && state.clubSubscriptions.length > 0) {
        return state.clubSubscriptions.find((subscription) => subscription.id === getters.selectedSubscriptionId);
      }
      return null;
    },
    clubSpecific(state) {
      if (!state.newUserClubSpecific && !state.userClubSpecific) return null;
      return {
        ...(state.userClubSpecific ?? {}),
        ...(state.newUserClubSpecific ?? {}),
      };
    },
    clubSpecificByKey: (state) => (key) => state.newUserClubSpecific?.[key] ?? state.userClubSpecific?.[key] ?? null,
    user(state) {
      return state.user;
    },
    getUserKey: (state) => (key) => state.newUser?.[key] ?? state.user?.[key] ?? null,
    newUser(state) {
      return state.newUser;
    },
    newUserClubSpecific(state) {
      return state.newUserClubSpecific;
    },
    preSelectedDate(state) {
      return state.preSelectedDate;
    },
    preSelectedSubscriptionId(state) {
      return state.preSelectedSubscriptionId;
    },
    userSubscription(state) {
      return state.userSubscription;
    },
    platform(state) {
      return state.platform;
    },
    appImages(state) {
      return state.appImages;
    },

    // To refactor (?)
    billingInfo(state) {
      return state.billingInfo;
    },
  },
  mutations: {
    // Firebase get only
    setFirebaseUser(state, payload) {
      state.firebaseUser = payload;
      console.log('🠖 Vuex update: firebaseUser', state.firebaseUser);
    },
    setSubscriptions(state, payload) {
      state.clubSubscriptions = payload;
      console.log('🠖 Vuex update: clubSubscriptions', state.clubSubscriptions);
    },
    setClub(state, payload) {
      state.club = payload;
      console.log('🠖 Vuex update: club', state.club);
    },
    setUser(state, payload) {
      state.user = payload;
      console.log('🠖 Vuex update: user', state.user);
    },
    setUserSubscription(state, payload) {
      state.userSubscription = payload;
      state.selectedSubscriptionId = payload?.subscription_id ?? null;
      console.log('🠖 Vuex update: userSubscription', state.userSubscription);
      console.log('🠖 Vuex update: selectedSubscriptionId', payload?.subscription_id ?? null);

      if (payload?.subscription_id) {
        state.newSelectedSubscriptionId = payload.subscription_id;
        console.log('🠖 Vuex update: newSelectedSubscriptionId', payload.subscription_id);
      }
    },
    setUserClubSpecific(state, payload) {
      state.userClubSpecific = payload;
      console.log('🠖 Vuex update: userClubSpecific', state.userClubSpecific);
    },
    setPreSelectedDate(state, payload) {
      state.preSelectedDate = payload;
      console.log('🠖 Vuex update: preSelectedDate', state.preSelectedDate);
    },
    setPreSelectedSubscriptionId(state, payload) {
      state.preSelectedSubscriptionId = payload;
      state.newSelectedSubscriptionId = payload;
      console.log('🠖 Vuex update: preSelectedSubscriptionId', state.preSelectedSubscriptionId);
      console.log('🠖 Vuex update: newSelectedSubscriptionId', state.newSelectedSubscriptionId);
    },

    // App user data
    setNewUser(state, payload) {
      state.newUser = { ...(state.newUser ?? {}), ...payload };
      console.log('🠖 Vuex update: newUser', state.newUser);
    },
    setNewSelectedSubscriptionId(state, payload) {
      state.newSelectedSubscriptionId = payload;
      console.log('🠖 Vuex update: newSelectedSubscriptionId', state.newSelectedSubscriptionId);
    },
    setNewUserClubSpecific(state, payload) {
      state.newUserClubSpecific = { ...(state.newUserClubSpecific ?? {}), ...payload };
      console.log('🠖 Vuex update: newUserClubSpecific', state.newUserClubSpecific);
    },

    // Refactor (?)
    setBillingInfo(state, payload) { // TODO: Save somewhere and use in Stripe
      state.billingInfo = { ...state.billingInfo, ...payload };
      console.log('🠖 Vuex update: billingInfo', state.billingInfo);
    },

    // UI
    savePlatform(state, payload) {
      state.platform = payload;
      console.log('🠖 Vuex update: platform', state.platform);
    },
    saveAppImages(state, payload) {
      state.appImages = payload;
      console.log('🠖 Vuex update: appImages', state.appImages);
    },

    // Reset Vuex
    async resetState(state) {
      const initial = defaultState();
      await Object.keys(initial).forEach((key) => { state[key] = initial[key]; });
    },

    // Manage Firestore subscriptions
    saveFirestoreSubscription,
    unbindFirestoreSubscription,
  },
  actions: {
    async bindFirebaseRef(context, { ref, mutationName, targetParameter }) {
      await subscribeToUpdates(ref, mutationName, context, targetParameter);
    },
  },
  plugins: [
    createPersistedState(),
  ],
});
