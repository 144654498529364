<template>
  <div class="klubba-header" :class="{ 'is-club': showClub }">
    <transition name="fade-left" mode="out-in">
      <div :key="showClub" class="left">
        <div class="logo-wrapper" :class="{ 'is-club': showClub }">
          <img src="@/assets/icons/logo.svg" class="logo klubba" v-if="!showClub" />
          <img src="" v-image-from-storage="clubLogo" class="logo club" v-else />
        </div>

        <div class="texts">
          <div class="suspended" v-if="showClub && club?.state === 'inactive'">Suspended club</div>
          <div class="title" :class="{ 'is-club': showClub }">{{ showClub ? `${club?.name ?? 'Club'} Member Registration` : 'KLUBBA' }}</div>
          <div class="description" v-if="!showClub">Membership Manager</div>
        </div>
      </div>
    </transition>

    <div class="right">
      <svg width="26" height="26" viewBox="0 0 26 26" xmlns="http://www.w3.org/2000/svg" class="info-icon" @click="isInfoModalOpened = true">
        <path fill-rule="evenodd" clip-rule="evenodd"
              d="M13 0.5C6.1075 0.5 0.5 6.1075 0.5 13C0.5 19.8925 6.1075 25.5 13 25.5C19.8925 25.5 25.5 19.8925 25.5 13C25.5 6.1075 19.8925 0.5 13 0.5ZM13 5.625C13.8975 5.625 14.625 6.35254 14.625 7.25C14.625 8.14746 13.8975 8.875 13 8.875C12.1025 8.875 11.375 8.14746 11.375 7.25C11.375 6.81902 11.5462 6.4057 11.851 6.10095C12.1557 5.79621 12.569 5.625 13 5.625ZM17 19.75H9.5V17.75H12.25V12.25H10.25V10.25H14.25V17.75H17V19.75Z" />
      </svg>
    </div>

    <teleport to="#app">
      <BaseModal :active="isInfoModalOpened" @close="isInfoModalOpened = false">
        <KlubbaRichText>
          <h1>Klubba membership manager</h1>
          <p>This vendor is using Klubba to manage their membership program. Klubba allows you to subscribe, see and manage subscriptions to the clubs you love with a convenient digital wallet. For more information, or to read our privacy policy, please visit <a href="https://myklubba.com" target="_blank">myklubba.com</a>.</p>
        </KlubbaRichText>
      </BaseModal>
    </teleport>
  </div>
</template>

<script>
import BaseModal from '../BaseModal.vue';
import KlubbaRichText from '../default/KlubbaRichText.vue';

export default {
  name: 'KlubbaHeader',
  components: { KlubbaRichText, BaseModal },
  props: {
    showClub: {
      type: Boolean,
    },
  },
  computed: {
    club() {
      return this.$store.getters.club;
    },
    clubLogo() {
      return this.club?.logo_image ?? '';
    },
  },
  data() {
    return {
      isInfoModalOpened: false,
    };
  },
};
</script>

<style lang="sass" scoped>
.klubba-header
  @apply w-full h-24 px-8 flex items-center justify-between z-10 text-white
  box-shadow: 0 2px 0 0 rgba(white, .05)
  background: var(--klubba-secondary-back-color)
  transition: background var(--transition-2x), color var(--transition-2x), opacity var(--transition-2x), margin var(--transition-2x)

  &.is-club
    background: var(--secondary-back-color, var(--klubba-secondary-back-color))
    color: var(--secondary-fore-color, var(--klubba-secondary-fore-color))

    .info-icon
      fill: var(--primary-back-color, var(--klubba-primary-back-color))

.left
  @apply flex items-center flex-grow mr-5

.right
  @apply flex-shrink-0

.logo-wrapper
  @apply block mr-4

  &.is-club
    @apply mr-5

.logo
  @apply block

  &.klubba
    @apply w-7

  &.club
    max-width: 72px
    max-height: 54px
    transform: translate3d(0, 0, 0)

.title
  @apply font-bold uppercase

  font-size: 16px
  letter-spacing: -0.1px
  line-height: normal

  &.is-club
    @apply font-bold

    font-size: 12px
    line-height: normal
    letter-spacing: 1.1px
    max-width: 200px

.description
  font-size: 13px
  line-height: normal
  letter-spacing: 0.5px

.info-icon
  @apply w-6 h-6 cursor-pointer flex-shrink-0
  fill: var(--klubba-primary-back-color)
  transition: fill var(--transition-2x)

.suspended
  @apply uppercase mb-1 font-bold opacity-50
  font-size: 11px
  line-height: 1
  letter-spacing: 1.1px
</style>
